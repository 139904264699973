import React from "react"

import styles from "./simpleList.module.scss"

const SimpleList = ({ title, items }) => {
  return (
    <div className={styles.simpleList}>
      {title && <h2 className={styles.title}>{title}</h2>}

      {items && (
        <ul className={styles.list}>
          {items.map((item, index) => (
            <li key={index} className={styles.item}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SimpleList
